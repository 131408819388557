<template>
  <div>
    <div v-if="getMainSearchCategory === 'Internal Number' || getMainSearchCategory === 'Claim Number'">
      <hr>
      <div class="row mb-1">
        <div class="col-6">
          <h1>Search Results</h1>
        </div>
        <div class="col-3">
          Include closed cases <toggle-button
            v-model="IncludeClosedCases"
            @change="IncludeClosedCases = $event.value"
          />
        </div>
        <div class="col-3">
          <b-form-group>
            <div class="d-flex align-items-right">
              <label class="mr-1" />
              <b-form-input
                v-model="searchFilterTerm"
                placeholder="Filter"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
      </div>

      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: false
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Internal Number -->
          <span v-if="props.column.field === 'InternalNumber'">
            <router-link
              :to="'cases/' + props.row.CaseID + '/detail'"
            >{{ props.row.InternalNumber }}</router-link>
          </span>

          <!-- Column: Subject -->
          <span v-else-if="props.column.field === 'Subject'">
            <router-link
              :to="'subject/' + props.row.SubjectID "
              onerror="onError"
            >{{ props.row.Subject }}</router-link>
          </span>

          <!-- Column: Date Closed -->
          <span v-else-if="props.column.field === 'DateClosed'">
            {{ props.formattedRow[props.column.field] | dateFormat}}
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing {{ displayStartIndex }} - {{ displayEndIndex }} of
                {{ totalResultCount }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['100', '150', '200']"
                class="mx-1"
                @input="(value) => {
                  props.perPageChanged({ currentPerPage: value });
                  onPageSizeChanged({ currentPerPage: value });
                }"
              />
              <span class="text-nowrap">per page</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :value="1"
                :total-rows="totalResultCount"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
                @page-click="onPageChanged"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
    <div v-if="getMainSearchCategory === 'Requestor'">
      <hr>
      <div class="row mb-1">
        <div class="col-6">
          <h1>Search Results</h1>
        </div>
        <div class="col-3" />
        <div class="col-3">
          <b-form-group>
            <div class="d-flex align-items-right">
              <label class="mr-1" />
              <b-form-input
                v-model="searchFilterName"
                placeholder="Filter Claims"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
      </div>

      <vue-good-table
        :columns="columnsRequestor"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: false
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'FullName'">
            <router-link
              :to="'client/requestor/' + props.row.UserID + '/view/' + props.row.ClientID + '/' + props.row.BranchID"
            >{{ props.row.FullName }}</router-link>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing {{ displayStartIndex }} - {{ displayEndIndex }} of
                {{ totalResultCount }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['100', '150', '200']"
                class="mx-1"
                @input="(value) => {
                  props.perPageChanged({ currentPerPage: value });
                  onPageSizeChanged({ currentPerPage: value });
                }"
              />
              <span class="text-nowrap">per page</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :value="1"
                :total-rows="totalResultCount"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
                @page-click="onPageChanged"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
    <div v-if="getMainSearchCategory === 'Subject'">
      <hr>
      <div class="row mb-1">
        <div class="col-6">
          <h1>Search Results</h1>
        </div>
        <div class="col-3" />
        <div class="col-3">
          <b-form-group>
            <div class="d-flex align-items-right">
              <label class="mr-1" />
              <b-form-input
                v-model="searchFilterName"
                placeholder="Filter Claims"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
      </div>

      <vue-good-table
        :columns="columnsSubject"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: false
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Subject -->
          <span
            v-if="props.column.field === 'FullName'"
            class="field-size"
          >
            <router-link
              :to="'subject/' + props.row.SubjectID "
              onerror="onError"
            >{{ props.row.FullName }} </router-link>
          </span>

          <!-- Column: Internal Number -->
          <span v-else-if="props.column.field === 'FileNumber'">
            <router-link
              :to="'cases/' + props.row.CaseID + '/detail'"
            >{{ props.row.FileNumber }}</router-link>
          </span>

          <!-- Column: Age -->
          <span v-else-if="props.column.field === 'Age'">
            <span>
              {{ props.formattedRow[props.column.field] | ageFormat }}
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing {{ displayStartIndex }} - {{ displayEndIndex }} of
                {{ totalResultCount }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['100', '150', '200']"
                class="mx-1"
                @input="(value) => {
                  props.perPageChanged({ currentPerPage: value });
                  onPageSizeChanged({ currentPerPage: value });
                }"
              />
              <span class="text-nowrap">per page</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :value="1"
                :total-rows="totalResultCount"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
                @page-click="onPageChanged"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import store from "@/store/index";
import APIService from "@core/utils/APIService";
import {mapGetters, mapMutations} from "vuex";
import {axiosInc} from "@core/utils/APIService";

const apiService = new APIService();

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  filters: {
    ageFormat(val) {
      if (val) return val;
      return "";
    },
    dateFormat(val) {
      if (val) {
        const date = new Date(val);
        const options = {month: '2-digit', day: '2-digit', year: 'numeric'}
        if (date) return date.toLocaleDateString("en-US", options);
      }
      if (val) return val.substr(0, 10);
      return val
    },
  },
  data() {
    return {
      pageLength: 100,
      currentPage: 1,
      displayStartIndex: 1,
      displayEndIndex: 100,
      dir: false,
      totalResultCount: 0,
      awaitingSearch: false,
      IncludeClosedCases: true,
      oldRequest: null,
      columns: [
        {
          label: "Claim Number",
          field: "ClaimFileNo",
        },
        {
          label: "Internal Number",
          field: "InternalNumber",
        },
        {
          label: "Subject",
          field: "Subject",
        },
        {
          label: "Client",
          field: "ClientName",
        },
        {
          label: "Branch",
          field: "BranchName",
        },
        {
          label: "Status",
          field: "Status",
        },
        {
          label: "Date Closed",
          field: "DateClosed",
        },
      ],
      columnsRequestor: [
        {
          label: "Name",
          field: "FullName",
        },
        {
          label: "Email",
          field: "EMail",
        },
        {
          label: "Client",
          field: "ClientName",
        },
        {
          label: "Branch",
          field: "BranchName",
        },
      ],
      columnsSubject: [
        {
          label: "Subject",
          field: "FullName",
        },
        {
          label: "Race",
          field: "Race",
        },
        {
          label: "Sex",
          field: "Gender",
        },
        {
          label: "Age",
          field: "Age",
        },
        {
          label: "Address 1",
          field: "Address1",
        },
        {
          label: "ACTIVE/MOST RECENT CASE",
          field: "FileNumber",
        },
      ],
      rows: [],
      searchFilterTerm: "",
      searchFilterName: "",
      status: [
        {
          1: "Active",
          2: "Disabled",
        },
        {
          1: "light-primary",
          2: "light-danger",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getMainSearchCategory: "search/getMainSearchCategory",
      getMainSearchItem: "search/getMainSearchItem",
    }),
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    getMainSearchCategory() {
      this.loadCasesPage(1);
    },
    getMainSearchItem() {
      this.loadCasesPage(1);
    },
    searchFilterTerm: function (val) {
        this.loadCasesPage(1);
    },
    IncludeClosedCases: function (val) {
      this.loadCasesPage(1);
    }
  },
  async mounted() {
    this.loadCasesPage(1);
  },
  methods: {
    loadCasesPage(pageNumber) {
      const signal = axiosInc.CancelToken.source();
      const request = { cancel: signal.cancel, msg: "Loading..." };
      if (this.oldRequest) this.oldRequest.cancel(this.oldRequest.msg);
      this.oldRequest = request;
      this.rows = [];

      var pageZeroIndex = pageNumber - 1;
      var startIndex = pageZeroIndex * this.pageLength;

      this.displayStartIndex = startIndex + 1;
      this.displayEndIndex = this.pageLength + this.displayStartIndex - 1;

      if (this.getMainSearchCategory && this.getMainSearchItem && this.getMainSearchItem.length >= 2) {
        const postData ={
            "entityType": this.getMainSearchCategory,
            "searchNameTerm": this.getMainSearchItem,
            "searchFilterTerm": this.searchFilterTerm,
            "max": this.pageLength,
            "skip": 0,
            "activeCases": !this.IncludeClosedCases,
        }
        apiService
            .post("search", postData, {cancelToken: signal.token})
            .then((response) => {
              if (response && response.data) {
                  this.loadCaseResults(response.data);
              }
            });
      }
    },
    loadRequestorResults(responseData) {
      this.rows = responseData.SearchResult || [];
      this.totalResultCount = responseData.TotalCount;
      this.displayEndIndex = this.rows.length + this.displayStartIndex - 1;
    },
    loadCaseResults(responseData) {
      this.rows = responseData.SearchResult || [];
      this.totalResultCount = responseData.TotalCount;
      this.displayEndIndex = this.rows.length + this.displayStartIndex - 1;
    },
    onPageChanged(bvEvent, page) {
      this.loadCasesPage(page);
    },
    onPageSizeChanged(newPageSize) {
      this.loadCasesPage(this.currentPage);
      this.pageLength = newPageSize.currentPerPage;
    },
    onError(err) {
      console.log("err", err)
    }
  },
}
</script>

<style>
.field-size {
  height: 1.7rem;
  display: block;
}
</style>
